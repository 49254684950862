:root {
  --foreground-rgb: 0, 0, 0;
  --content-de-emph-fg-rgb: 40, 40, 40;
  --background-rgb: 255, 255, 255;
  --content-bg-rgb: 210, 225, 250;
  --content-hover-bg-rgb: 220, 240, 255;
  --button-bg-rgb: 220, 240, 255;
  --button-hover-bg-rgb: 255, 255, 255;
  --button-active-bg-rgb: 200, 220, 240;
  --button-active-border-rgb: 140, 160, 180;
  --textbox-bg-rgb: 230, 248, 255;
  --fg-link-rgb: 0, 0, 255;
  --fg-link-visited-rgb: 100, 0, 100;
  --fg-link-hover-rgb: 200, 0, 200;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 220, 220, 230;
    --content-de-emph-fg-rgb: 160, 160, 160;
    --background-rgb: 0, 0, 0;
    --content-bg-rgb: 20, 20, 20;
    --content-hover-bg-rgb: 20, 30, 40;
    --button-bg-rgb: 40, 40, 40;
    --button-hover-bg-rgb: 60, 60, 60;
    --button-active-bg-rgb: 60, 80, 100;
    --button-active-border-rgb: 80, 100, 120;
    --textbox-bg-rgb: 30, 30, 30;
    --fg-link-rgb: 150, 180, 255;
    --fg-link-visited-rgb: 180, 160, 220;
    --fg-link-hover-rgb: 180, 200, 255;
  }
}
